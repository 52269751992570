import * as React from 'react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { FileUploadResponse } from '@api/endpoints'
import { uploadFile } from '@api/endpoints/forms'
import { QueryErrorHandler, useDefaultQueryErrorHandlerPublic, UseMutationProps } from '@hooks/ReactQuery'

export const useMutationUploadFile = ({
  onSuccess
}: UseMutationProps): UseMutationResult<FileUploadResponse, unknown, File> => {
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()

  const mutation = useMutation({
    mutationFn: uploadFile
  })

  const errorHandler: QueryErrorHandler = (e) => {
    // TODO: Handler errors here
    defaultErrorHandler(e)
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  React.useEffect(() => {
    if (mutation.isSuccess) {
      void onSuccess?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess])

  return mutation
}
