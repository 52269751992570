import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { isEmptyString } from '@digital-magic/ts-common-utils'
import { VehicleRegistrationNumber } from '@api/endpoints'
import { ApiErrorCode, extractApiErrorCode } from '@api/errors'
import { findVehicleFromMntByRegNumber, VehicleMntSearchResponse } from '@api/endpoints/vehicles'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { useSnackbarActions } from '@hooks/useSnackbarActions'
import { queryKeyFindVehicleFromMntByRegNumber } from './queryKeys'

export const useQueryFindVehicleFromMntByRegNumber = (
  regNumber: VehicleRegistrationNumber | undefined,
  enabled?: boolean
): UseQueryResult<Readonly<VehicleMntSearchResponse>> => {
  const { t } = useTranslation()
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const { enqueueErrorMsg } = useSnackbarActions()

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeyFindVehicleFromMntByRegNumber(regNumber ?? ''),
    queryFn: () => findVehicleFromMntByRegNumber(regNumber ?? ''),
    enabled: enabled ?? !isEmptyString(regNumber)
  })

  React.useEffect(() => {
    if (query.error) {
      if (extractApiErrorCode(query.error) === ApiErrorCode.enum.CAR_NOT_FOUND) {
        enqueueErrorMsg(t('global.errors.error_regNum_not_found'))
      } else {
        errorHandler(query.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
