import * as React from 'react'
import Modal from '@mui/material/Modal'
import { useTranslation } from 'react-i18next'
import { LinearProgress, Typography } from '@mui/material'
import styles from './UploadingModal.module.css'

type UploadingModalProps = Readonly<{
  open: boolean
  onClose?: () => void
  uploadedFiles: number
  totalFiles: number
}>

export const UploadingModal: React.FC<UploadingModalProps> = ({ open, onClose, uploadedFiles, totalFiles }) => {
  const { t } = useTranslation()
  const progressValue = React.useMemo(
    () => (totalFiles > 0 ? (uploadedFiles * 100) / totalFiles : 0),
    [uploadedFiles, totalFiles]
  )
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="files-modal-dialog-title"
      aria-describedby="files-modal-dialog-description"
    >
      <div className={styles.modalContainer}>
        <Typography id="files-modal-dialog-title" variant="h6" component="h2">
          {t('global.messages.please_wait')}
        </Typography>
        <Typography id="files-modal-dialog-description" variant="h6" component="h3">
          {t('global.fileUpload.uploading', { current: uploadedFiles, total: totalFiles })}
        </Typography>
        <LinearProgress variant="determinate" className={styles.uploadProgress} value={progressValue} />
      </div>
    </Modal>
  )
}
