import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CurriedHandler, hasValue } from '@digital-magic/ts-common-utils'
import { useSnackbarActions } from '@hooks/useSnackbarActions'
import { FileInput } from '../FileInput'
import { FileInputProps } from '../types'
import styles from './FileUploadManager.module.css'
import { mergeFileArrays } from './utils'
import { FlexContainer } from '@layout/FlexContainer'

export type FileUploadManagerProps = Omit<FileInputProps, 'onFilesSelected'> &
  Readonly<{
    maxFiles?: number
    merge?: (previous: Array<File>, files: Array<File> | null) => Array<File>
    onFilesChange?: (files: Array<File>) => void
    renderValue?: (
      values: Array<File>,
      handleDelete: CurriedHandler<number, React.MouseEvent<HTMLButtonElement>>
    ) => React.ReactElement
  }>

export const FileUploadManager: React.FC<FileUploadManagerProps> = ({
  maxFiles,
  merge = mergeFileArrays,
  onFilesChange,
  renderValue,
  disabled,
  ...fileInputProps
}) => {
  const { t } = useTranslation()
  const { enqueueErrorMsg } = useSnackbarActions()

  const [files, setFiles] = React.useState<Array<File>>([])

  const remainingFiles = hasValue(maxFiles) ? (files.length > maxFiles ? 0 : maxFiles - files.length) : undefined

  const handleFilesSelected = React.useCallback(
    (files: Array<File>) => {
      if (remainingFiles && files.length > remainingFiles) {
        enqueueErrorMsg(t('global.fileUpload.errors.maxFilesError', { maxFiles: maxFiles }))
      } else {
        setFiles((currentFiles) => merge(currentFiles, files))
      }
    },
    [enqueueErrorMsg, remainingFiles, maxFiles, merge, t]
  )

  const handleDeleteFile = React.useCallback(
    (index: number) =>
      (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault()
        setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index))
      },
    []
  )

  React.useEffect(() => {
    onFilesChange?.(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  return (
    <FlexContainer vertical responsiveGap className={styles.fileUploadManagerContainer}>
      {renderValue && renderValue(files, handleDeleteFile)}
      {((fileInputProps.multiple && (!hasValue(remainingFiles) || remainingFiles > 0)) || files.length === 0) && (
        <FileInput onFilesSelected={handleFilesSelected} disabled={disabled} {...fileInputProps} />
      )}
    </FlexContainer>
  )
}
