import * as React from 'react'
import { FieldPathByValue, FieldValues, PathValue } from 'react-hook-form'
import { FormFileField, FormFileInputProps } from '@controls/Form'
import { ButtonClose } from '@controls/buttons'
import { FileFieldType } from '../../types'
import styles from './FormFileUploadManager.module.css'

export type FormFileUploadManagerProps<
  TField extends PathValue<TFieldValues, TName>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, FileFieldType> = FieldPathByValue<TFieldValues, FileFieldType>
> = FormFileInputProps<TField, TFieldValues, TName>

export const FormFileUploadManager = <
  TField extends PathValue<TFieldValues, TName>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, FileFieldType> = FieldPathByValue<TFieldValues, FileFieldType>
>({
  control,
  name,
  ...props
}: FormFileUploadManagerProps<TField, TFieldValues, TName>): React.ReactElement => (
  <FormFileField
    control={control}
    name={name}
    renderValue={(values: Array<File>, handleDelete) => (
      <div className={styles.fileGrid}>
        {values.map((file, index) => (
          <div key={index} className={styles.fileGrid__item}>
            <div className={styles.fileGrid__image}>
              <img src={URL.createObjectURL(file)} alt={file.name} />
            </div>
            <span className={styles.fileGrid__text}>{file.name}</span>
            <ButtonClose className={styles.fileGrid__delete} onClick={handleDelete(index)} />
          </div>
        ))}
      </div>
    )}
    {...props}
  />
)
