import * as React from 'react'
import { Controller, FieldError, FieldPathByValue, FieldValues, PathValue } from 'react-hook-form'
import { CurriedHandler, hasValue } from '@digital-magic/ts-common-utils'
import { FileUploadManager, FileUploadManagerProps } from '@controls/file'
import { FileFieldType } from '../../types'
import { CommonFormFieldProps } from '../types'

export type FormFileInputProps<
  TField extends PathValue<TFieldValues, TName>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, FileFieldType> = FieldPathByValue<TFieldValues, FileFieldType>
> = Omit<
  FileUploadManagerProps,
  'name' | 'form' | 'onChange' | 'error' | 'errorText' | 'children' | 'onFilesChange' | 'renderValue'
> &
  CommonFormFieldProps<TFieldValues> & {
    name: TName
    renderValue?: (
      values: TField,
      handleDelete: CurriedHandler<number, React.MouseEvent<HTMLButtonElement>>
    ) => React.ReactElement
  }

export const FormFileField = <
  TField extends PathValue<TFieldValues, TName>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, FileFieldType> = FieldPathByValue<TFieldValues, FileFieldType>
>({
  control,
  name,
  renderValue,
  ...props
}: FormFileInputProps<TField, TFieldValues, TName>): React.ReactElement => (
  <Controller
    control={control}
    name={name}
    render={({ field, fieldState }) => (
      <FileUploadManager
        inputVisible={!field.value || props.multiple}
        name={field.name}
        error={fieldState.error !== undefined}
        // TODO: Maybe in future releases fieldState.error will be correct and consider Array of errors
        errorText={
          fieldState.error?.message ??
          (fieldState.error as unknown as Array<FieldError>)?.find((e) => hasValue(e?.message))?.message
        }
        // TODO: Check if working correctly
        onFilesChange={field.onChange}
        renderValue={renderValue ? (values, handleDelete) => renderValue?.(values as TField, handleDelete) : undefined}
        {...props}
      />
    )}
  />
)
