// a helper function in case the control is mapped to an array of files in the form
/*
 * @Deprecated
 */
export const mergeFileArraysDeprecated = (
  previous: Array<File> | undefined,
  files: Array<File> | undefined
): Array<File> => {
  const merged = [...(previous ?? []), ...(files ?? [])]
  const map = new Map(merged.map((x) => [x.name, x])).values()
  return Array.from(map)
}

export const mergeFileArrays = (previous: Array<File>, files: Array<File> | null): Array<File> => {
  const merged = [...(previous ?? []), ...(files ?? [])]
  const map = new Map(merged.map((x) => [x.name, x])).values()
  return Array.from(map)
}

export const replaceFileArray = (_: Array<File>, files: Array<File> | null): Array<File> => {
  return files ?? []
}
